import Tracker, { SanitizeLevel } from "@openreplay/tracker";
import trackerAssist from "@openreplay/tracker-assist";
import {
	ExcelExport,
	type ExcelExportColumnProps,
} from "@progress/kendo-react-excel-export";
import type {
	GridCellProps,
	GridColumnProps,
} from "@progress/kendo-react-grid";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import dayjs from "dayjs";
import { isEqual, uniqBy } from "lodash";
import qs from "qs";
import {
	type ComponentProps,
	type ComponentType,
	type DependencyList,
	type Dispatch,
	type MutableRefObject,
	type ReactNode,
	useCallback,
	useMemo,
	useRef,
} from "react";
import {
	type FieldPath,
	type FieldValues,
	type Path,
	get,
} from "react-hook-form";
import { toast } from "react-toastify";
import { useEffectOnce, useInterval, useLocalStorage } from "react-use";
import superjson from "superjson";
import type { AnyObject, ObjectSchema, SchemaDescription } from "yup";
import type { GenericGrid } from "./GenericGrid";
import { AuditApi } from "./api/AuditApi";
import { AuthenticationApi } from "./api/AuthenticationApi";
import { JobApi, type ViewLegStatusResponse } from "./api/JobApi";
import { NotificationApi } from "./api/NotificationApi";
import { ReportApi, type TemplateNameType } from "./api/ReportApi";
import { SageApi } from "./api/SageApi";
import { authStore } from "./authStore";

export const toSimpleDateString = (x: Date) => {
	if (!dayjs(x).isValid()) return "";
	return x.toLocaleDateString("en-GB", {
		day: "2-digit",
		month: "2-digit",
		year: "2-digit",
	});
};
export const toDateString = (x: Date | undefined | null) => {
	if (!x) return "";
	if (!dayjs(x).isValid()) return "";
	return x.toLocaleDateString("en-GB", {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
	});
};
export const toDatetimeString = (x: string | Date | undefined | null) => {
	if (!x) return "";
	const date = typeof x === "string" ? new Date(x) : x;
	if (!dayjs(date).isValid()) return "";
	return date.toLocaleDateString("en-GB", {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
		hour: "2-digit",
		minute: "2-digit",
	});
};

export const toCurrency = (x: number | null | undefined, currency?: string) =>
	new Intl.NumberFormat("en-US", {
		style: currency ? "currency" : undefined,
		currency,
		minimumFractionDigits: 2,
		currencyDisplay: "narrowSymbol",
	}).format(x || 0);

export type LegAction =
	| "allowEdit"
	| "allowDelete"
	| "allowChangeStatus"
	| "allowGenerateDeliveryTicket"
	| "allowGenerateCollectionNote"
	| "allowGenerateSubcontractorOrder"
	| "allowAudit";

export type JobAction =
	| "allowViewDetails"
	| "allowEdit"
	| "allowPause"
	| "allowDuplicate"
	| "allowSendToInvoice"
	| "allowCancel"
	| "allowBulkLegsStatusChange"
	| "allowGenerateFileFront"
	| "allowForceComplete"
	| "isInvoiced"
	| "allowInvoiceSentTransition"
	| "allowAudit";

export type LoadAction =
	| "allowViewDetails"
	| "allowEdit"
	| "allowPause"
	| "allowGenerateManifest"
	| "allowViewClearCustomsDoc"
	| "allowCancel"
	| "allowGenerateAllDocuments"
	| "allowGenerateManifestAndDeliveryTickets"
	| "allowGenerateManifestAndCmr"
	| "allowAudit";

export type LegActions = { [key in LegAction]: boolean | null };
export type JobActions = { [key in JobAction]: boolean | null };
export type LoadActions = { [key in LoadAction]: boolean | null };

export enum ObjectType {
	Job = 0,
	Leg = 1,
	Load = 2,
}

export enum TableNameType {
	None = "none",
	Attachment = "attachment",
	Customer = "customer",
	Goods = "goods",
	Job = "job",
	Leg = "leg",
	Load = "load",
}

export const objectTypeNames: { [key in ObjectType]: string } = {
	[ObjectType.Job]: "Job",
	[ObjectType.Leg]: "Leg",
	[ObjectType.Load]: "Load",
};

export enum NewLegType {
	Invalid = 0,
	Storage = 1,
	Ferry = 2,
	ClearCustoms = 3,
	Collection = 4,
	Segment = 5,
	Delivery = 6,
	CollectDeliver = 7,
	Blank = 8,
	ContainerPickUp = 9,
	ContainerDropOff = 10,
	ContainerGoodsCollection = 11,
	ContainerGoodsDelivery = 12,
	LoadAndLash = 13,
	Devan = 14,
}

export const legTypeNames: { [key in NewLegType]: string } = {
	[NewLegType.Invalid]: "Invalid",
	[NewLegType.Storage]: "Storage",
	[NewLegType.Ferry]: "Ferry",
	[NewLegType.ClearCustoms]: "Clear customs",
	[NewLegType.Collection]: "Collection",
	[NewLegType.Segment]: "Segment",
	[NewLegType.Delivery]: "Delivery",
	[NewLegType.CollectDeliver]: "Collect and deliver",
	[NewLegType.Blank]: "Blank",
	[NewLegType.ContainerPickUp]: "Container pick up",
	[NewLegType.ContainerDropOff]: "Container drop off",
	[NewLegType.ContainerGoodsCollection]: "Container goods collection",
	[NewLegType.ContainerGoodsDelivery]: "Container goods delivery",
	[NewLegType.LoadAndLash]: "Load and lash",
	[NewLegType.Devan]: "Devan",
};

enum InvoiceFrequency {
	Daily = 0,
	Weekly = 1,
	Monthly = 2,
}

export const invoiceFrequencyNames: { [key in InvoiceFrequency]: string } = {
	[InvoiceFrequency.Daily]: "Daily",
	[InvoiceFrequency.Weekly]: "Weekly",
	[InvoiceFrequency.Monthly]: "Monthly",
};

export enum InvoiceType {
	Invalid = 0,
	Single = 1,
	Multiple = 2,
}

export const invoiceTypeNames: { [key in InvoiceType]: string } = {
	[InvoiceType.Invalid]: "Invalid",
	[InvoiceType.Single]: "Single",
	[InvoiceType.Multiple]: "Multiple",
};

export enum InvoiceSentMethod {
	Invalid = 0,
	Manual = 1,
	Email = 2,
}

export const invoiceSentMethodNames: { [key in InvoiceSentMethod]: string } = {
	[InvoiceSentMethod.Invalid]: "Invalid",
	[InvoiceSentMethod.Manual]: "Manual",
	[InvoiceSentMethod.Email]: "Email",
};

enum CustomerStatus {
	Invalid = 0,
	Active = 1,
	Deleted = 2,
	Inactive = 3,
}

export const customerStatusNames: { [key in CustomerStatus]: string } = {
	[CustomerStatus.Invalid]: "Invalid",
	[CustomerStatus.Active]: "Active",
	[CustomerStatus.Deleted]: "Deleted",
	[CustomerStatus.Inactive]: "Inactive",
};

enum UserStatus {
	Invalid = 0,
	Active = 1,
	Deleted = 2,
}

export const userStatusNames: { [key in UserStatus]: string } = {
	[UserStatus.Invalid]: "Invalid",
	[UserStatus.Active]: "Active",
	[UserStatus.Deleted]: "Deleted",
};

enum TrailerStatus {
	Invalid = 0,
	Active = 1,
	Deleted = 2,
}

export const trailerStatusNames: { [key in TrailerStatus]: string } = {
	[TrailerStatus.Invalid]: "Invalid",
	[TrailerStatus.Active]: "Active",
	[TrailerStatus.Deleted]: "Deleted",
};

export enum LegStatusType {
	New = 1,
	Planned = 2,
	Accepted = 3,
	InStartPosition = 4,
	Underway = 5,
	InEndPosition = 6,
	SignedOff = 7,
	NotStarted = 8,
	DocumentsReady = 9,
	Clearing = 10,
	CustomsCleared = 11,
	WorkFinished = 12,
	BookingInProgress = 13,
	Booked = 14,
	Paused = 15,
	Cancelled = 16,
	Archived = 17,
	PartOfLoad = 18,
	ReadyForWork = 19,
}

export const legStatusTypeNames: { [key in LegStatusType]: string } = {
	[LegStatusType.New]: "New",
	[LegStatusType.Planned]: "Planned",
	[LegStatusType.Accepted]: "Accepted",
	[LegStatusType.InStartPosition]: "In start position",
	[LegStatusType.Underway]: "Underway",
	[LegStatusType.InEndPosition]: "In end position",
	[LegStatusType.SignedOff]: "Signed off",
	[LegStatusType.NotStarted]: "Not started",
	[LegStatusType.DocumentsReady]: "Documents ready",
	[LegStatusType.Clearing]: "Clearing",
	[LegStatusType.CustomsCleared]: "Customs cleared",
	[LegStatusType.WorkFinished]: "Work finished",
	[LegStatusType.BookingInProgress]: "Booking in progress",
	[LegStatusType.Booked]: "Booked",
	[LegStatusType.Paused]: "Paused",
	[LegStatusType.Cancelled]: "Cancelled",
	[LegStatusType.Archived]: "Archived",
	[LegStatusType.PartOfLoad]: "Part of load",
	[LegStatusType.ReadyForWork]: "Ready for work",
};

export enum LoadStatus {
	New = 1,
	Building = 2,
	Accepted = 3,
	Underway = 4,
	WorkFinished = 5,
	Paused = 6,
	Cancelled = 7,
	Archived = 8,
	Planned = 9,
}

export const loadStatusNames: { [key in LoadStatus]: string } = {
	[LoadStatus.New]: "New",
	[LoadStatus.Building]: "Building",
	[LoadStatus.Accepted]: "Accepted",
	[LoadStatus.Underway]: "Underway",
	[LoadStatus.WorkFinished]: "Work finished",
	[LoadStatus.Paused]: "Paused",
	[LoadStatus.Cancelled]: "Cancelled",
	[LoadStatus.Archived]: "Archived",
	[LoadStatus.Planned]: "Planned",
};

const getColorValue = (
	colorName: string,
): { backgroundColor: string; color: string } => {
	switch (colorName) {
		case "gray":
			return { backgroundColor: "#9d9dab", color: "white" };
		case "yellow":
			return { backgroundColor: "#e5c100", color: "black" };
		case "orange":
			return { backgroundColor: "#e59500", color: "black" };
		case "blue":
			return { backgroundColor: "#0563c1", color: "white" };
		case "violet":
			return { backgroundColor: "#2e294e", color: "white" };
		case "pink":
			return { backgroundColor: "#e50683", color: "black" };
		case "green":
			return { backgroundColor: "#0ba008", color: "white" };
		case "darkGreen":
			return { backgroundColor: "#0b615b", color: "white" };
		case "lightGray":
			return { backgroundColor: "#a5c4d4", color: "black" };
		case "red":
			return { backgroundColor: "#e50606", color: "white" };
		case "purple":
			return { backgroundColor: "#7b439e", color: "white" };
		default:
			return { backgroundColor: "#9d9dab", color: "black" };
	}
};

export const loadStatusNamesAndColors: {
	[key in LoadStatus]: {
		name: string;
		backgroundColor: string;
		color: string;
	};
} = {
	[LoadStatus.New]: {
		name: "New",
		...getColorValue("gray"),
	},
	[LoadStatus.Building]: {
		name: "Building",
		...getColorValue("yellow"),
	},
	[LoadStatus.Accepted]: {
		name: "Accepted",
		...getColorValue("green"),
	},
	[LoadStatus.Underway]: {
		name: "Underway",
		...getColorValue("darkGreen"),
	},
	[LoadStatus.WorkFinished]: {
		name: "Work finished",
		...getColorValue("green"),
	},
	[LoadStatus.Paused]: {
		name: "Paused",
		...getColorValue("violet"),
	},
	[LoadStatus.Cancelled]: {
		name: "Cancelled",
		...getColorValue("red"),
	},
	[LoadStatus.Archived]: {
		name: "Archived",
		...getColorValue("gray"),
	},
	[LoadStatus.Planned]: {
		name: "Planned",
		...getColorValue("yellow"),
	},
};

export const legStatusWithNameList = Object.entries(legStatusTypeNames).map(
	([value, label]) => ({ id: Number.parseInt(value), name: label }),
);

enum NewSupplierInvoiceType {
	NoInvoice = 0,
	ToBeInvoiced = 1,
	Invoiced = 2,
	Review = 3,
}

export const supplierInvoiceTypeNames: {
	[key in NewSupplierInvoiceType]: string;
} = {
	[NewSupplierInvoiceType.NoInvoice]: "No invoice",
	[NewSupplierInvoiceType.ToBeInvoiced]: "To be invoiced",
	[NewSupplierInvoiceType.Invoiced]: "Invoiced",
	[NewSupplierInvoiceType.Review]: "Review",
};

enum JobTypeStatus {
	Active = 1,
	Inactive = 2,
	Deleted = 3,
}

export const jobTypeStatusNames: { [key in JobTypeStatus]: string } = {
	[JobTypeStatus.Active]: "Active",
	[JobTypeStatus.Inactive]: "Inactive",
	[JobTypeStatus.Deleted]: "Deleted",
};

enum LegTypeStatus {
	Invalid = 0,
	Active = 1,
	Inactive = 2,
	Deleted = 3,
}

export const legTypeStatusNames: { [key in LegTypeStatus]: string } = {
	[LegTypeStatus.Invalid]: "Invalid",
	[LegTypeStatus.Active]: "Active",
	[LegTypeStatus.Inactive]: "Inactive",
	[LegTypeStatus.Deleted]: "Deleted",
};

export enum NewJobStatus {
	UNKNNOWN = 0,
	NEW = 1,
	PLANNED = 2,
	LOADED = 3,
	COMPLETED = 4,
	PAUSED = 5,
	CANCELLED = 6,
	ARCHIVED = 7,
	READY_FOR_INVOICE = 8,
	CHECKED = 9,
	INVOICE_GENERATED = 10,
	INVOICE_SENT = 11,
	INVOICE_FAILED = 12,
	READY_FOR_REINVOICE = 13,
	CHECKED_AGAIN = 14,
	REINVOICE_GENERATED = 15,
	REINVOICE_SENT = 16,
	SCHEDULED_FOR_INVOICE = 17,
}
export const jobStatusNamesAndColors: {
	[key in NewJobStatus]: {
		name: string;
		backgroundColor: string;
		color: string;
	};
} = {
	[NewJobStatus.UNKNNOWN]: {
		name: "Unknown",
		...getColorValue("gray"),
	},
	[NewJobStatus.NEW]: {
		name: "New",
		...getColorValue("gray"),
	},
	[NewJobStatus.PLANNED]: {
		name: "Planned",
		...getColorValue("yellow"),
	},
	[NewJobStatus.LOADED]: {
		name: "Loaded",
		...getColorValue("darkGreen"),
	},
	[NewJobStatus.COMPLETED]: {
		name: "Completed",
		...getColorValue("green"),
	},
	[NewJobStatus.PAUSED]: {
		name: "Paused",
		...getColorValue("violet"),
	},
	[NewJobStatus.CANCELLED]: {
		name: "Cancelled",
		...getColorValue("red"),
	},
	[NewJobStatus.ARCHIVED]: {
		name: "Archived",
		...getColorValue("gray"),
	},
	[NewJobStatus.READY_FOR_INVOICE]: {
		name: "Unchecked",
		...getColorValue("blue"),
	},
	[NewJobStatus.CHECKED]: {
		name: "Checked",
		...getColorValue("green"),
	},
	[NewJobStatus.INVOICE_GENERATED]: {
		name: "Invoice generated",
		...getColorValue("green"),
	},
	[NewJobStatus.INVOICE_SENT]: {
		name: "Invoice sent",
		...getColorValue("blue"),
	},
	[NewJobStatus.INVOICE_FAILED]: {
		name: "Invoice failed",
		...getColorValue("red"),
	},
	[NewJobStatus.READY_FOR_REINVOICE]: {
		name: "Ready for re-invoice",
		...getColorValue("yellow"),
	},
	[NewJobStatus.CHECKED_AGAIN]: {
		name: "Checked again",
		...getColorValue("green"),
	},
	[NewJobStatus.REINVOICE_GENERATED]: {
		name: "Re-invoice generated",
		...getColorValue("green"),
	},
	[NewJobStatus.REINVOICE_SENT]: {
		name: "Re-invoice sent",
		...getColorValue("green"),
	},
	[NewJobStatus.SCHEDULED_FOR_INVOICE]: {
		name: "Scheduled for invoice",
		...getColorValue("yellow"),
	},
};
export const jobStatusWithNameList = Object.entries(
	jobStatusNamesAndColors,
).map(([key, value]) => ({ id: Number.parseInt(key), name: value.name }));
export const invoiceStatusWithNameList = Object.entries(jobStatusNamesAndColors)
	.filter(([key]) => Number.parseInt(key) > 7)
	.map(([key, value]) => ({ id: Number.parseInt(key), name: value.name }));

enum NewErrorCode {
	DEFAULT = 0,
	UNAUTHORIZED = 5001,
	INVALID_CREDENTIALS = 5002,
	BAD_REQUEST = 5003,
	FORBIDDEN = 5004,
	INVALID_TOKEN = 5005,
	MESSAGE_NOT_SENT = 5006,
	DUPLICATE = 5007,
	INVALID_MODEL = 5008,
	NOT_CREATED = 5009,
	INVALID_FILE = 5010,
	INVALID_ACTIVATION_ACCOUNT_TOKEN = 6002,
}
const newCreateAxiosInstance = (baseUrl: string) => {
	const axiosInstance = axios.create({
		baseURL: baseUrl,
		headers: { "Content-Type": "application/json", Application: "client" },
		paramsSerializer: (x) => qs.stringify(x, { arrayFormat: "repeat" }),
	});
	axiosInstance.interceptors.request.use(
		(config) => {
			const persistedAuthentication = localStorage.getItem(
				"persist:authentication",
			);
			if (persistedAuthentication) {
				const authenticationState = JSON.parse(persistedAuthentication);
				const { token, currentBusinessUnit } = authenticationState;
				if (token) config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
				if (currentBusinessUnit)
					config.headers.BusinessUnit = JSON.parse(currentBusinessUnit).id;
			} else {
				const token = authStore.value?.user?.token;
				config.headers.Authorization = `Bearer ${token}`;
				const buId = authStore.value?.businessUnit?.id;
				config.headers.BusinessUnit = buId;
			}
			return config;
		},
		(error) => Promise.reject(error),
	);

	axiosInstance.interceptors.response.use(
		(response) => {
			if (!response) return Promise.reject("Response canceled!");
			return response;
		},
		(error) => {
			const responseData = error.response?.data;
			const statusCode = error.response?.status;

			if (statusCode === 401) {
				localStorage.clear();
				window.location.href = "/login";
			} else if (responseData) {
				switch (responseData.errorCode) {
					case NewErrorCode.INVALID_TOKEN:
						localStorage.clear();
						window.location.href = "/login";
						break;
					case NewErrorCode.FORBIDDEN:
						window.location.href = "/business-unit-selection";
						break;
				}
			}
			return Promise.reject({
				code: responseData?.errorCode?.toString() || "Unknown",
				message: responseData?.message || "An error occurred",
			});
		},
	);
	return axiosInstance;
};
let apiUrl = import.meta.env.VITE_API_URL ?? "https://localhost:7216";
const injectedApiUrl = "https://api-uat.celerum.online";
if (injectedApiUrl.startsWith("http")) apiUrl = injectedApiUrl;
export const axiosInstance = newCreateAxiosInstance(apiUrl);
export const authenticationApi = new AuthenticationApi();
authenticationApi.instance = axiosInstance;
export const jobApi = new JobApi();
jobApi.instance = axiosInstance;
const notificationApi = new NotificationApi();
notificationApi.instance = axiosInstance;
const reportApi = new ReportApi();
reportApi.instance = axiosInstance;
const sageApi = new SageApi();
sageApi.instance = axiosInstance;
export const auditApi = new AuditApi();
auditApi.instance = axiosInstance;

// for quick debugging
Object.assign(window, {
	api: {
		authenticationApi,
		jobApi,
		notificationApi,
		reportApi,
		sageApi,
		auditApi,
	},
});

const fetchUpdateInfo = () =>
	fetch("/", { method: "HEAD", headers: { "Cache-Control": "no-cache" } })
		.then((x) => x.headers.get("last-modified") ?? "")
		.catch(() => "");
const isUpdateAvailable = () =>
	fetchUpdateInfo().then(
		(x) =>
			x !== "" &&
			new Date(document.lastModified).getTime() !== new Date(x).getTime(),
	);
export const useNotifyUpdate = (delay = 60000) => {
	const check = () =>
		isUpdateAvailable().then((x) => {
			if (!x) return;
			toast.success("New version! Please refresh the page.", {
				toastId: "systemVersionUpdate",
				autoClose: false,
			});
		});
	useEffectOnce(() => void check());
	return useInterval(check, delay);
};

export const useLS = <T>(
	key: string,
	initialValue: T,
): [T, Dispatch<React.SetStateAction<T>>] => {
	const [value, setValue] = useLocalStorage<T>(key, initialValue, {
		raw: false,
		deserializer: superjson.parse,
		serializer: superjson.stringify,
	});
	return [value as T, setValue as Dispatch<React.SetStateAction<T>>];
};

type TypedGridCellProps<T> = Omit<GridCellProps, "dataItem"> & {
	dataItem: T;
};

export type ColumnField<T> = Extract<keyof T, string> | "actions";
export type TypedGridColumnProps<T> = Omit<
	GridColumnProps,
	"cell" | "field"
> & {
	field: ColumnField<T>;
	cell?: ComponentType<TypedGridCellProps<T>>;
	hidden?: boolean;
};

type NewLegStatus = {
	id: LegStatusType;
	name: string;
	color: string;
	legTypes: NewLegType[];
};
const toLegStatuses = (data: ViewLegStatusResponse): NewLegStatus => ({
	id: data.id || 0,
	name: data.name || "",
	color: data.color || "",
	legTypes: data.legTypes || [],
});
export const useLegStatus = () => {
	const _legStatuses = useQuery({
		queryKey: ["jobApi.leg.legStatusList"],
		queryFn: () =>
			jobApi.leg.legStatusesList().then((x) => x.data.map(toLegStatuses)),
		initialData: [],
	});
	const availableStatuses = (legType: NewLegType) =>
		_legStatuses.data.filter((x) => x.legTypes.includes(legType));
	const getLegColor = (legStatus: LegStatusType) =>
		_legStatuses.data.find((x) => x.id === legStatus)?.color;
	return { legStatuses: _legStatuses.data, availableStatuses, getLegColor };
};

export const downloadExcelFile = <T extends object>(
	name: string,
	data: T[],
	columns: TypedGridColumnProps<T>[],
) =>
	new ExcelExport({
		fileName: `${name}-${dayjs().format("YYYY-MM-DD_HH-mm-ss")}`,
		filterable: true,
	}).save(data, columns as ExcelExportColumnProps[]);

export const filterData = <T extends object>(data: T[], search: string) => {
	const wordSearched = search
		.toLowerCase()
		.split(" ")
		.filter((x) => x);
	const filtered = data.filter((item) => {
		const itemString = JSON.stringify(Object.values(item)).toLowerCase();
		const result = wordSearched.every((word) => itemString.includes(word));
		return result;
	});
	return filtered;
};

export type GenericFormInputSelectOption = {
	value: string;
	label: string;
};
const useGenericLookup = (
	dataFetcher: () => Promise<GenericFormInputSelectOption[]>,
	queryKey: string,
	deps: DependencyList = [],
	limit = 20,
) => {
	const data = useQuery({
		queryKey: [queryKey, ...deps],
		queryFn: dataFetcher,
	});
	const dataWithLowerCaseLabel = useMemo(
		() =>
			data.data?.map((x) => ({
				...x,
				lowerCaseLabel: x.label.toLowerCase(),
			})) ?? [],
		[data.data],
	);
	return useCallback(
		async (search: string, values?: string[]) => {
			const stringifyedValues = values?.map((x) => `${x}`) ?? [];
			const fromValues = dataWithLowerCaseLabel.filter((x) =>
				stringifyedValues.includes(x.value),
			);
			const words = search
				.toLowerCase()
				.split(" ")
				.filter((x) => x);
			const fromSearch = dataWithLowerCaseLabel.filter((x) =>
				words.every((word) => x.lowerCaseLabel.includes(word)),
			);
			const result = uniqBy(fromValues.concat(fromSearch), (x) => x.value);
			const limitedResults = result.slice(0, limit + fromValues.length);
			return limitedResults;
		},
		[dataWithLowerCaseLabel, limit],
	);
};

export const useLookupOrganisations = () =>
	useGenericLookup(
		() =>
			authenticationApi.organisation
				.organisationLookupList({})
				.then((x) => x.data.map((x) => ({ value: `${x.id}`, label: x.name }))),
		"lookup.authenticationApi.organisation.organisationLookupList",
	);
export const useLookupRoles = () =>
	useGenericLookup(
		() =>
			authenticationApi.role
				.roleLookupList({})
				.then((x) => x.data.map((x) => ({ value: `${x.id}`, label: x.name }))),
		"lookup.authenticationApi.role.roleLookupList",
	);
export const useLookupUsers = () =>
	useGenericLookup(
		() =>
			authenticationApi.user
				.userLookupList({})
				.then((x) => x.data.map((x) => ({ value: `${x.id}`, label: x.name }))),
		"lookup.authenticationApi.user.userLookupList",
	);
export const useLookupBusinessUnits = () =>
	useGenericLookup(
		() =>
			authenticationApi.businessUnit
				.businessUnitLookupList({})
				.then((x) => x.data.map((x) => ({ value: `${x.id}`, label: x.name }))),
		"lookup.authenticationApi.businessUnit.businessUnitLookupList",
	);

export const useLookupJobTypes = () =>
	useGenericLookup(
		() =>
			jobApi.jobType
				.jobTypeLookupList({})
				.then((x) =>
					x.data?.map((x) => ({ value: `${x.id}`, label: x.name ?? "?" })),
				),
		"lookup.jobApi.jobType.jobTypeLookupList",
	);
export const useLookupLocations = (deps: DependencyList = []) =>
	useGenericLookup(
		() =>
			jobApi.location
				.locationLookupList({})
				.then((x) =>
					x.data.map((x) => ({ value: `${x.id}`, label: x.name ?? "?" })),
				),
		"lookup.jobApi.location.locationLookupList",
		deps,
	);
export const useLookupConstraints = () =>
	useGenericLookup(
		() =>
			jobApi.constraint
				.constraintLookupList({})
				.then((x) => x.data?.map((x) => ({ value: `${x.id}`, label: x.name }))),
		"lookup.jobApi.constraint.constraintLookupList",
	);
export const useLookupCustomers = () =>
	useGenericLookup(
		() =>
			jobApi.customer
				.customerLookupList({})
				.then((x) =>
					x.data?.map((x) => ({ value: `${x.id}`, label: x.name ?? "?" })),
				),
		"lookup.jobApi.customer.customerLookupList",
	);
export const useLookupCurrencies = () =>
	useGenericLookup(
		() =>
			jobApi.currency
				.currencyLookupList({})
				.then((x) => x.data?.map((x) => ({ value: `${x.id}`, label: x.code }))),
		"lookup.jobApi.currency.currencyLookupList",
	);
export const useLookupDrivers = () =>
	useGenericLookup(
		() =>
			jobApi.driver
				.driverLookupList({})
				.then((x) => x.data?.map((x) => ({ value: `${x.id}`, label: x.name }))),
		"lookup.jobApi.driver.driverLookupList",
	);
export const useLookupTrailers = () =>
	useGenericLookup(
		() =>
			jobApi.trailer
				.trailerLookupList({})
				.then((x) =>
					x.data?.map((x) => ({ value: `${x.id}`, label: x.name ?? "?" })),
				),
		"lookup.jobApi.trailer.trailerLookupList",
	);
export const useLookupTrucks = () =>
	useGenericLookup(
		() =>
			jobApi.truck
				.truckLookupList({})
				.then((x) =>
					x.data?.map((x) => ({ value: `${x.id}`, label: x.name ?? "?" })),
				),
		"lookup.jobApi.truck.truckLookupList",
	);
export const useLookupSubcontractors = () =>
	useGenericLookup(
		() =>
			jobApi.subcontractor
				.subcontractorLookupList({})
				.then((x) =>
					x.data?.map((x) => ({ value: `${x.id}`, label: x.name ?? "?" })),
				),
		"lookup.jobApi.subcontractor.subcontractorLookupList",
	);
export const useLookupTruckTypes = () =>
	useGenericLookup(
		() =>
			jobApi.truckType
				.truckTypeLookupList({})
				.then((x) =>
					x.data?.map((x) => ({ value: `${x.id}`, label: x.name ?? "?" })),
				),
		"lookup.jobApi.truckType.truckTypeLookupList",
	);
export const useLookupTrailerTypes = () =>
	useGenericLookup(
		() =>
			jobApi.trailerType
				.trailerTypeLookupList({})
				.then((x) =>
					x.data?.map((x) => ({ value: `${x.id}`, label: x.name ?? "?" })),
				),
		"lookup.jobApi.trailerType.trailerTypeLookupList",
	);
export const useLookupChecklists = () =>
	useGenericLookup(
		() =>
			jobApi.checklist
				.checklistLookupList({})
				.then((x) => x.data?.map((x) => ({ value: `${x.id}`, label: x.name }))),
		"lookup.jobApi.checklist.checklistLookupList",
	);
export const useLookupLoadTypes = () =>
	useGenericLookup(
		() =>
			jobApi.loadType
				.loadTypeLookupList({})
				.then((x) =>
					x.data?.map((x) => ({ value: `${x.id}`, label: x.name ?? "?" })),
				),
		"lookup.jobApi.loadType.loadTypeLookupList",
	);

export const useLookupInvoiceTemplates = (type: number) =>
	useGenericLookup(
		() =>
			reportApi.template
				.templateV1Detail(type as TemplateNameType)
				.then((x) => x.data?.map((x) => ({ value: x.name, label: x.name }))),
		"lookup.reportApi.template.templateV1Detail",
		[type],
	);

export const useLookupTrailerStatuses = () =>
	useGenericLookup(
		() =>
			Promise.resolve(
				Object.entries(trailerStatusNames)
					.filter(([value]) => value !== TrailerStatus.Invalid.toString())
					.map(([value, label]) => ({ value, label })),
			),
		"lookup.trailerStatusNames",
	);
export const useLookupLegTypes = () =>
	useGenericLookup(
		() =>
			Promise.resolve(
				Object.entries(legTypeNames)
					.filter(([value]) => value !== NewLegType.Invalid.toString())
					.map(([value, label]) => ({ value, label })),
			),
		"lookup.legTypeNames",
	);
export const useLookupJobTypeStatuses = () =>
	useGenericLookup(
		() =>
			Promise.resolve(
				Object.entries(jobTypeStatusNames).map(([value, label]) => ({
					value,
					label,
				})),
			),
		"lookup.jobTypeStatusNames",
	);
export const useLookupLegTypeStatuses = () =>
	useGenericLookup(
		() =>
			Promise.resolve(
				Object.entries(legTypeStatusNames)
					.filter(([value]) => value !== LegTypeStatus.Invalid.toString())
					.map(([value, label]) => ({ value, label })),
			),
		"lookup.legTypeStatusNames",
	);
export const useLookupUserStatuses = () =>
	useGenericLookup(
		() =>
			Promise.resolve(
				Object.entries(userStatusNames)
					.filter(([value]) => value !== UserStatus.Invalid.toString())
					.map(([value, label]) => ({ value, label })),
			),
		"lookup.userStatusNames",
	);
export const useLookupCustomerStatuses = () =>
	useGenericLookup(
		() =>
			Promise.resolve(
				Object.entries(customerStatusNames)
					.filter(([value]) => value !== CustomerStatus.Invalid.toString())
					.map(([value, label]) => ({ value, label })),
			),
		"lookup.customerStatusNames",
	);
export const useLookupInvoiceSentMethods = () =>
	useGenericLookup(
		() =>
			Promise.resolve(
				Object.entries(invoiceSentMethodNames)
					.filter(([value]) => value !== InvoiceSentMethod.Invalid.toString())
					.map(([value, label]) => ({ value, label })),
			),
		"lookup.invoiceSentMethodNames",
	);
export const useLookupInvoiceTypes = () =>
	useGenericLookup(
		() =>
			Promise.resolve(
				Object.entries(invoiceTypeNames)
					.filter(([value]) => value !== InvoiceType.Invalid.toString())
					.map(([value, label]) => ({ value, label })),
			),
		"lookup.invoiceTypeNames",
	);
export const useLookupInvoiceFrequencies = () =>
	useGenericLookup(
		() =>
			Promise.resolve(
				Object.entries(invoiceFrequencyNames).map(([value, label]) => ({
					value,
					label,
				})),
			),
		"lookup.invoiceFrequencyNames",
	);

const getSchemaFieldSpec = <T extends AnyObject>(
	schema: ObjectSchema<T>,
	path: Path<T>,
) => {
	const parts = path.replace(/\./g, ".fields.");
	return get(schema.fields, parts).describe() as SchemaDescription;
};

export const useSchemaInfo = <T extends FieldValues>(
	name: FieldPath<T>,
	schema?: ObjectSchema<T>,
	label?: ReactNode,
	required?: boolean,
) =>
	useMemo(() => {
		if (!schema) return { _label: label, _required: required };
		const spec = getSchemaFieldSpec(schema, name);
		const isRequired = !spec.optional && !spec.nullable;
		return { _label: label ?? spec.label, _required: required ?? isRequired };
	}, [schema, name, label, required]);

export const useTracker = () =>
	useEffectOnce(() => {
		if (
			location.hostname === "localhost" ||
			/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/.test(location.hostname)
		) {
			console.log("Tracker disabled on localhost or IP address");
			return;
		}
		const tracker = new Tracker({
			projectKey: "7k02qAVZRMJ2xRiKx2ns",
			__DISABLE_SECURE_MODE: true,
			respectDoNotTrack: false,
			captureExceptions: true,
			captureIFrames: true,
			capturePageLoadTimings: true,
			capturePageRenderTimings: true,
			capturePerformance: true,
			captureResourceTimings: true,
			obscureInputDates: false,
			obscureInputEmails: false,
			obscureInputNumbers: false,
			obscureTextEmails: false,
			obscureTextNumbers: false,
			domSanitizer: () => SanitizeLevel.Plain,
			network: {
				captureInIframes: true,
				capturePayload: true,
				ignoreHeaders: false,
				failuresOnly: false,
				sessionTokenHeader: false,
			},
		});
		tracker.use(trackerAssist());
		tracker.start();
		const hostname = window.location.hostname;
		const trackerSetUserId = () => {
			const persistAuth = JSON.parse(
				localStorage["persist:authentication"] ?? "{}",
			);
			const currentUser = JSON.parse(persistAuth?.currentUser ?? "{}");
			const firstName = currentUser?.firstName ?? "";
			const lastName = currentUser?.lastName ?? "";
			const username = `${firstName} ${lastName}`.trim() || "Unknown";
			tracker.setUserID(`${username} (${hostname})`);
		};
		trackerSetUserId();
		tracker.setMetadata("hostname", hostname);
		const intervalId = setInterval(trackerSetUserId, 5000);
		return () => {
			clearInterval(intervalId);
			tracker.stop();
		};
	});

const hasChanged = <T extends unknown[]>(
	ref: MutableRefObject<T>,
	array: T,
	compare: <T>(a: T, b: T) => boolean,
): boolean => {
	if (!Array.isArray(array)) return true;
	if (ref.current === array) return false;
	if (ref.current.length !== array.length) return true;
	return ref.current.some((a, i) => !compare(a, array[i]));
};
const getCurrentArray = <T extends unknown[]>(
	ref: MutableRefObject<T>,
	array: T,
	compare: <T>(a: T, b: T) => boolean,
): T => {
	if (hasChanged(ref, array, compare)) ref.current = array;
	return ref.current;
};
export const useStableArray = <T extends unknown[]>(
	array: T,
	compare: <T>(a: T, b: T) => boolean = isEqual,
): T => {
	const ref = useRef(array);
	return getCurrentArray<T>(ref, array, compare);
};

export type WithId = { id: string | number };
export type WithName = { name: string };
export type GenericGridProps<T extends WithId> = Omit<
	ComponentProps<typeof GenericGrid<T>>,
	"defaultColumns" | "name"
>;

export const noInvalidDate = (x: unknown) =>
	Number.isNaN(Date.parse(x as string)) ? undefined : x;
export const noNaN = (x: unknown) => (Number.isNaN(x) ? undefined : x);

export enum DocumentUsage {
	Job = 3,
	Load = 4,
	Leg = 5,
}
export const uploadDocuments = async (
	files: File[],
	entityId: string | number,
	usage: DocumentUsage,
	isPod: boolean,
): Promise<void> => {
	const formData = new FormData();
	for (const file of files) formData.append("files", file);
	await jobApi.instance.post(
		`/job-gateway/Attachment/${entityId}?usage=${usage}&isPod=${isPod}`,
		formData,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		},
	);
};
export const toasted = <T>(promise: Promise<T>, actionName = "Actioning") => {
	return toast.promise(promise, {
		pending: `${actionName}...`,
		success: `...${actionName}!`,
		error: {
			render: (error) =>
				`...${actionName} failed: ${
					(error.data as Error)?.message ?? error.data
				}`,
		},
	});
};

export const uncheckGridSelection = () => {
	const inputCheck: HTMLInputElement | null = document.querySelector(
		"input[aria-label='Select Row']",
	);
	inputCheck?.click();
};
